export default {
  "upsell-cta": "yQ",
  "discover-badge": "ye",
  "role-selection-item": "yB",
  "unavailable": "yE",
  "role-headline": "yd",
  "illustration": "yI",
  "role-description": "yt",
  "description-item": "yM",
  "description-icon": "yP",
  "description-text": "yl",
  "current-role": "yX",
  "icon-outlined": "yC"
};
