/* import __COLOCATED_TEMPLATE__ from './not-eligible-state.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { EmptyStatesDiscover } from '@repo/domain-kit/pricing';
import dayjs from 'dayjs';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

import { NotEligibleState } from 'qonto/react/financing/components/pay-later/cockpit/not-eligible-state';

interface FinancingPayLaterCockpitNotEligibleStateSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FinancingPayLaterCockpitNotEligibleStateComponent extends Component<FinancingPayLaterCockpitNotEligibleStateSignature> {
  emptyStatesDiscover = EmptyStatesDiscover;
  notEligibleState = NotEligibleState;

  @service declare segment: Services['segment'];
  @service declare router: Services['router'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare flowLinkManager: Services['flowLinkManager'];

  get isOrgOlderThan6Months() {
    return dayjs(this.organizationManager.organization.legalRegistrationDate).isSameOrBefore(
      dayjs().subtract(6, 'months')
    );
  }

  @action
  onPrimaryCtaClick() {
    if (this.isOrgOlderThan6Months) {
      this.segment.track('pay-later-partners_clicked', {
        origin: 'cockpit',
      });
      this.router.transitionTo('financing.partners');
    } else {
      this.segment.track('pay-later_cockpit_external-account-clicked', {
        origin: 'non-eligible-less-than-6m',
      });
      this.flowLinkManager.transitionTo({
        name: 'external-account-import',
        stepId: 'select',
        queryParams: {
          origin: 'financing.pay-later',
        },
      });
    }
  }

  @action
  onSecondaryCtaClick() {
    if (this.isOrgOlderThan6Months) {
      this.segment.track('pay-later_cockpit_external-account-clicked', {
        origin: 'non-eligible',
      });
      this.flowLinkManager.transitionTo({
        name: 'external-account-import',
        stepId: 'select',
        queryParams: {
          origin: 'financing.pay-later',
        },
      });
    } else {
      let href = this.zendeskLocalization.getLocalizedArticle('8271688');

      this.segment.track('pay-later-faq_clicked', {
        origin: 'cockpit_noneligible',
      });
      window.open(href, '_blank', 'noopener noreferrer');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Financing::PayLater::Cockpit::NotEligibleState': typeof FinancingPayLaterCockpitNotEligibleStateComponent;
  }
}
