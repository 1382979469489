/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
// @ts-nocheck
/* eslint-disable @qonto/no-import-roles-constants */
import { action, get } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Disclaimer, TextAreaField } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import CURRENCIES from 'qonto/constants/currencies';
import { PAY_LATER_SIGNATURE_STATUS } from 'qonto/constants/financing';
import { ROLES } from 'qonto/constants/membership';
// @ts-expect-error
import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';
import {
  DISCLAIMER_TYPES,
  INSTANT_DISCLAIMER_TYPES,
  SPEND_LIMIT_TYPES,
  TRANSFER_FLOW_ORIGIN,
} from 'qonto/constants/transfers';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { prepareLimitsData } from 'qonto/utils/transfers';

interface FlowsTransfersSepaNewSummarySignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsTransfersSepaNewSummaryComponent extends Component<FlowsTransfersSepaNewSummarySignature> {
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;
  textAreaField = TextAreaField;

  @service declare abilities: Services['abilities'];
  @service declare featuresManager: Services['featuresManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare store: Services['store'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare sentry: Services['sentry'];

  @tracked isRequestValidationEnabled = false;
  @tracked isPayLater = false;
  @service declare sensitiveActions: Services['sensitiveActions'];

  constructor(owner: unknown, args: FlowsTransfersSepaNewSummarySignature['Args']) {
    // @ts-expect-error
    super(owner, args);

    // @ts-expect-error
    let { sepaTransfer, signature, confirmationResult, origin, invoice } = this.args.context;

    this.segment.track('transfer-sepa_summary_displayed', {
      'sepa-warnings': confirmationResult.warnings,
      ...(origin && { origin }),
      ...(invoice && {
        ...(variation('feature--boolean-improve-invoice-payment-flows') && { flow: 'byinvoice' }),
        is_einvoice: invoice?.isElectronicInvoice ?? false,
      }),
    });

    this.isPayLater = sepaTransfer.isPayLater;

    sepaTransfer.instant = this.isInstantAvailable;

    if (signature?.status === PAY_LATER_SIGNATURE_STATUS.SUCCESS) {
      // @ts-expect-error
      this.args.context.isPayLaterContractSigned = true;
      // @ts-expect-error
      this.sensitiveActions.runTask.perform(this.saveTransferTask).catch(ignoreCancelation);
    }
  }

  @action
  togglePayLater() {
    // @ts-expect-error
    let { instantPayLaterAvailable } = this.args.context.confirmationResult;

    if (!this.isPayLater && !instantPayLaterAvailable) {
      // @ts-expect-error
      this.args.context.sepaTransfer.instant = false;
    }

    this.isPayLater = !this.isPayLater;

    // @ts-expect-error
    this.args.context.sepaTransfer.operationType = this.isPayLater ? 'pay_later' : 'scheduled';
    // @ts-expect-error
    this.args.context.sepaTransfer.financingInstallments = 3;

    this.segment.track('pay_later-toggle_switch', { state: this.isPayLater ? 'on' : 'off' });
  }

  #getTransferOptions() {
    const isQontoToQontoTransfer = this.beneficiary?.get('qontoBankAccount');
    const { scheduledDate } = this.sepaTransfer;
    const isToday = scheduledDate && dayjs(scheduledDate).isSame(dayjs(), 'day');
    const isScheduledTransfer = !isToday && scheduledDate;
    return { isQontoToQontoTransfer, isScheduledTransfer };
  }

  get dateLabel() {
    const { scheduledDate, instant } = this.sepaTransfer;
    const { isScheduledTransfer } = this.#getTransferOptions();

    if (isScheduledTransfer) {
      return this.intl.t('labels.start_date');
    }

    return this.intl.t('transfers.modals.confirm_transfer.estimated_arrival.label');
  }

  get transferDate() {
    const { scheduledDate, instant } = this.sepaTransfer;
    const { isScheduledTransfer, isQontoToQontoTransfer } = this.#getTransferOptions();

    if (isScheduledTransfer) {
      return dayjs(scheduledDate).format('DD/MM/YYYY');
    }

    return this.intl.t(
      instant || isQontoToQontoTransfer
        ? 'transfers.modals.confirm_transfer.estimated_arrival.instant'
        : 'transfers.modals.confirm_transfer.estimated_arrival.standard'
    );
  }

  get shouldShowStandardTooltip() {
    const { scheduledDate, instant } = this.sepaTransfer;
    const { isScheduledTransfer, isQontoToQontoTransfer } = this.#getTransferOptions();

    if (isScheduledTransfer) {
      return false;
    }

    return !(instant || isQontoToQontoTransfer);
  }

  get isInstantPayLaterDisabled() {
    // @ts-expect-error
    let { instantPayLaterAvailable } = this.args.context.confirmationResult;

    return this.isPayLater && !instantPayLaterAvailable;
  }

  get isPayLaterUnavailable() {
    // @ts-expect-error
    let { didValidateFinancing, didInstallmentsFail } = this.args.context;
    return !didValidateFinancing || didInstallmentsFail;
  }

  get aboveLimitType() {
    return this.transferLimitsData.aboveLimitType;
  }

  get beneficiary() {
    return this.sepaTransfer.get('beneficiary');
  }

  get beneficiaryLabel() {
    let SUPPLIER_ORIGINS = [
      TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES,
      TRANSFER_FLOW_ORIGIN.PAY_LATER_COCKPIT,
      TRANSFER_FLOW_ORIGIN.PAY_BY_INVOICE,
    ];
    let origin = this.args.context.origin;

    if (
      variation('feature--boolean-improve-invoice-payment-flows') &&
      origin &&
      SUPPLIER_ORIGINS.includes(origin)
    ) {
      return this.intl.t('supplier-invoices.supplier-invoice-details.supplier-information.label');
    }
    return this.intl.t('labels.beneficiary');
  }

  get canSeeBalance() {
    return this.abilities.can('see balance bankAccount');
  }

  get disclaimers() {
    // @ts-expect-error
    let { warnings, errors, fees, role } = this.args.context.confirmationResult || {};
    let disclaimers = this._getSepaDisclaimers({ warnings, errors, fees, role });
    return disclaimers.length &&
      disclaimers.some(disclaimer => disclaimer.type === DISCLAIMER_TYPES.KYB_WAITING)
      ? disclaimers.filter(disclaimer => disclaimer.type !== DISCLAIMER_TYPES.KYC_WAITING)
      : disclaimers;
  }

  get generalDisclaimers() {
    let filteredTypes = Object.values(INSTANT_DISCLAIMER_TYPES);

    return this.disclaimers.filter(disclaimer => {
      if (disclaimer.type === DISCLAIMER_TYPES.QONTO_BANK_ACCOUNT) {
        return false;
      }
      // @ts-expect-error
      return !filteredTypes.includes(disclaimer.type);
    });
  }

  get hasConfirmationErrors() {
    // @ts-expect-error
    return this.args.context.confirmationResult?.errors?.length > 0;
  }

  get instantDisclaimers() {
    return this.disclaimers.filter(disclaimer =>
      // @ts-expect-error
      Object.values(INSTANT_DISCLAIMER_TYPES).includes(disclaimer.type)
    );
  }

  get isAboveTransferLimits() {
    return Boolean(this.aboveLimitType);
  }

  get isInstantAvailable() {
    // @ts-expect-error
    let { confirmationResult, isInstantFallback } = this.args.context;

    if (isInstantFallback) return false;

    let { instantAvailable, instantPayLaterAvailable } = confirmationResult || {};

    let isInstantAvailable = this.isPayLater ? instantPayLaterAvailable : instantAvailable;

    return (
      // @ts-expect-error
      this.featuresManager.flags.sepaInstantOut && isInstantAvailable && !this.hasConfirmationErrors
    );
  }

  get mainAccountName() {
    // @ts-expect-error
    return this.args.context.sepaTransfer.organization.get('mainAccount').name;
  }

  get isInstantToggleDisabled() {
    return !isEmpty(this.instantDisclaimers) || !this.isInstantAvailable;
  }

  get isBookTransfer() {
    return this.disclaimers.some(el => el.type === DISCLAIMER_TYPES.QONTO_BANK_ACCOUNT);
  }

  get isInstantVisible() {
    return (
      this.isInstantAvailable ||
      (!isEmpty(this.instantDisclaimers) && !this.isBookTransfer) ||
      // @ts-expect-error
      this.args.context.isInstantFallback
    );
  }

  get organization() {
    return this.sepaTransfer.get('organization');
  }

  get sepaTransfer() {
    // @ts-expect-error
    return this.args.context.sepaTransfer;
  }

  get transferEndDate() {
    return dayjs(this.sepaTransfer.standingEndingDate).format('DD/MM/YYYY');
  }

  get transferLimitExceededDisclaimerMessage() {
    let { perTransfer, monthly, monthSpendings } = this.transferLimitsData;

    if (this.aboveLimitType === SPEND_LIMIT_TYPES.PER_TRANSFER) {
      return this.intl.t('requests.transfers.modals.confirm_request.disclaimers.per-transfer', {
        per_transfer_limit: perTransfer,
      });
    }

    if (this.aboveLimitType === SPEND_LIMIT_TYPES.MONTHLY) {
      return this.intl.t('requests.transfers.modals.confirm_request.disclaimers.monthly-standard', {
        balance_monthly_transfer_limit: this.intl.formatNumber(monthly - monthSpendings, {
          currency: CURRENCIES.default,
          minimumFractionDigits: 0,
          style: 'currency',
        }),
      });
    }

    if (this.aboveLimitType === SPEND_LIMIT_TYPES.RECURRING) {
      return this.intl.t(
        'requests.transfers.modals.confirm_request.disclaimers.monthly-per-transfer-recurring'
      );
    }
  }

  get showPayLaterToggle() {
    // @ts-expect-error
    let { invoice, isPayLaterEligible } = this.args.context;

    return (
      this.abilities.can('view pay later toggle in financing') && invoice?.id && isPayLaterEligible
    );
  }

  get transferLimitsData() {
    // @ts-expect-error
    let { spendLimits, warnings } = this.args.context.confirmationResult;
    return prepareLimitsData(warnings, spendLimits);
  }

  get transferRequest() {
    // @ts-expect-error
    return this.args.context.transferRequest;
  }

  get instantTooltipMessage() {
    return this.instantDisclaimers.map(({ message }) => message).join(' ');
  }

  get organizationLegalCountry() {
    return this.organizationManager.organization.legalCountry;
  }

  get financingDetails() {
    // @ts-expect-error
    let { payLaterInstallments, sepaTransfer } = this.args.context;

    let selectedInstallment = payLaterInstallments.options.find(
      // @ts-expect-error
      option => option.numberOfInstallments === sepaTransfer.financingInstallments
    );

    return {
      totalAmount: selectedInstallment.totalAmount,
      totalFee: selectedInstallment.totalFees,
    };
  }

  @action
  toggleInstant() {
    this.sepaTransfer.instant = !this.sepaTransfer.instant;
    this.segment.track('transfer-sepa_instant-toggle_switch', {
      state: this.sepaTransfer.instant ? 'on' : 'off',
    });
  }

  @action
  // @ts-expect-error
  handleInstallmentOptionChange(option) {
    // @ts-expect-error
    this.args.context.sepaTransfer.financingInstallments = option;
  }

  @action
  handleConfirm() {
    // @ts-expect-error
    if (this.isPayLater && !this.args.context.isPayLaterContractSigned) {
      // @ts-expect-error
      this.args.transitionToNext();
    } else {
      // @ts-expect-error
      this.sensitiveActions.runTask.perform(this.saveTransferTask).catch(ignoreCancelation);
    }
  }

  saveTransferRequestTask = dropTask(async () => {
    this.isRequestValidationEnabled = false;

    await this.transferRequest.validate();

    let isRequestValid = this.transferRequest.validations.isValid;

    if (!isRequestValid) {
      this.isRequestValidationEnabled = true;
      return;
    }

    // @ts-expect-error
    let { context, transitionToNext } = this.args;

    try {
      let response = await this.transferRequest.save();

      context.transferRequest = this.store.peekRecord('request-transfer', response.id);

      let savedAttachements = this.transferRequest
        .get('attachments')
        // @ts-expect-error
        .filter(item => get(item, 'isNew'));
      // @ts-expect-error
      savedAttachements.forEach(attachment => attachment.unloadRecord());

      this.segment.track('request_admin_approval_clicked');

      await this.unarchiveSupplierTask.perform().catch(ignoreCancelation);

      transitionToNext();
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  unarchiveSupplierTask = dropTask(async () => {
    // @ts-expect-error
    let { supplierInvoiceId } = this.args.context;
    if (!supplierInvoiceId || this.abilities.cannot('update supplier')) return;

    try {
      let supplierInvoice = await this.store.findRecord('supplier-invoice', supplierInvoiceId);

      let supplierId = supplierInvoice?.supplierSnapshot?.id;
      if (!supplierId) return;
      let supplier = await this.store.findRecord('supplier', supplierId);

      if (supplier?.status === SUPPLIER_STATUSES.ARCHIVED) {
        await supplier.unarchive();
      }
    } catch (error) {
      // In case of error silently ignore it
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  saveTransferTask = dropTask(async () => {
    this.sepaTransfer.addIdempotencyHeader();

    // @ts-expect-error
    let { context, transitionToNext } = this.args;
    const { origin } = context;

    try {
      let savedSepaTransfer = await this.sepaTransfer.save();

      context.sepaTransfer = this.store.peekRecord('transfer', savedSepaTransfer.id);

      let savedAttachements = this.sepaTransfer
        .get('attachments')
        // @ts-expect-error
        .filter(item => get(item, 'isNew'));

      // @ts-expect-error
      savedAttachements.forEach(attachment => attachment.unloadRecord());

      this._trackTransferCreation();

      await this.unarchiveSupplierTask.perform().catch(ignoreCancelation);

      if (origin === TRANSFER_FLOW_ORIGIN.UPCOMING_TRANSACTIONS) {
        if (this.sepaTransfer.scheduledDate) {
          this.toastFlashMessages.toastSuccess(
            this.intl.t('transfers.modals.success.scheduled.title')
          );
        } else {
          this.toastFlashMessages.toastSuccess(
            this.intl.t('transfers.modals.success.regular.title')
          );
        }

        this.args.completeFlow();
        return;
      }

      transitionToNext();
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    } finally {
      this.sepaTransfer.removeIdempotencyHeader();
    }
  });

  // @ts-expect-error
  _getSepaDisclaimers({ warnings = [], errors = [], fees = {}, role }) {
    let disclaimerTypes = [
      ...new Set([
        ...warnings,
        ...errors,
        // @ts-expect-error
        ...(fees && fees.amount > 0 ? [DISCLAIMER_TYPES.COST] : []),
        ...(this.isInstantPayLaterDisabled
          ? [DISCLAIMER_TYPES.INSTANT_NOT_AVAILABLE_WITH_PAY_LATER]
          : []),
        ...(!this.organizationManager.membership.kycAccepted ? [DISCLAIMER_TYPES.KYC_WAITING] : []),
      ]),
    ];

    let disclaimers = this._getSepaDisclaimersList({ fees, role });

    // @ts-expect-error
    let selectDisclaimer = disclaimer => disclaimerTypes.find(type => type === disclaimer.type);
    return disclaimers.filter(selectDisclaimer);
  }

  get bookTransferDisclaimer() {
    return {
      type: DISCLAIMER_TYPES.QONTO_BANK_ACCOUNT,
      variant: 'inline',
      level: 'info',
      message: this.intl.t('transfers.warnings.qonto_bank_account'),
    };
  }

  // @ts-expect-error
  _getSepaDisclaimersList({ fees, role }) {
    return [
      this.bookTransferDisclaimer,
      {
        type: DISCLAIMER_TYPES.ALREADY_ISSUED,
        variant: 'inline',
        level: 'warning',
        message: this.intl.t('transfers.warnings.already_issued'),
      },
      {
        type: DISCLAIMER_TYPES.BILLER_INSUFFICIENT_FUNDS,
        variant: 'inline',
        level: 'error',
        message:
          role === ROLES.MANAGER
            ? this.intl.t('transfers.warnings.manager.insufficient_funds')
            : this.intl.t('transfers.warnings.insufficient_funds'),
      },
      {
        type: DISCLAIMER_TYPES.COST,
        variant: 'inline',
        level: 'warning',
        message: this.intl.t('transfers.warnings.cost', {
          // @ts-expect-error
          computed_amount: this.intl.formatMoney(fees.amount, {
            currency: fees.amount_currency,
          }),
        }),
      },
      {
        type: DISCLAIMER_TYPES.KYB_WAITING,
        variant: 'block',
        level: 'warning',
        message: this.intl.t('transfers.warnings.kyb_waiting'),
      },
      {
        type: DISCLAIMER_TYPES.KYC_WAITING,
        variant: 'block',
        level: 'warning',
        message: this.intl.t('transfers.warnings.kyc-waiting'),
      },
      {
        type: DISCLAIMER_TYPES.TOP_UP_REQUIRED,
        variant: 'inline',
        level: 'warning',
        message: this.intl.t('transfers.warnings.top_up_required'),
      },
      {
        type: DISCLAIMER_TYPES.INSTANT_NON_REACHABLE_BIC,
        variant: 'inline',
        level: 'info',
        message: this.intl.t('transfers.warnings.instant-non-reachable-bic'),
      },
      {
        type: DISCLAIMER_TYPES.INSTANT_UNDER_MAINTENANCE,
        variant: 'inline',
        level: 'warning',
        message: this.intl.t('transfers.warnings.instant-under-maintenance'),
      },
      {
        type: DISCLAIMER_TYPES.INSTANT_AMOUNT_ABOVE_THRESHOLD,
        variant: 'inline',
        level: 'info',
        message: this.intl.t('transfers.warnings.instant-amount-above-threshold'),
      },
      {
        type: DISCLAIMER_TYPES.INSTANT_OPERATIONAL_UNAVAILABILITY,
        variant: 'inline',
        level: 'info',
        message: this.intl.t('transfers.warnings.instant-operational-unavailability'),
      },
      {
        type: DISCLAIMER_TYPES.INSTANT_FUNCTIONAL_UNAVAILABILITY,
        variant: 'inline',
        level: 'info',
        message: this.intl.t('transfers.warnings.instant-functional-unavailability'),
      },
      {
        type: DISCLAIMER_TYPES.INSTANT_NOT_AVAILABLE_WITH_PAY_LATER,
        variant: 'inline',
        level: 'info',
        message: this.intl.t('transfers.warnings.pay-later.instant-unavailable'),
      },
    ];
  }

  _trackTransferCreation() {
    // @ts-expect-error
    let { invoice, isInstantFallback, origin } = this.args.context;

    if (isInstantFallback) {
      this.segment.track('transfer_create_submitted', {
        is_instant_resent_as_regular: true,
      });
    } else {
      let {
        attachments,
        labels,
        note,
        notifyByEmail,
        email,
        isPayLater,
        bankAccount,
        vatRate,
        vatAmount,
        instant,
        scheduledDate,
      } = this.sepaTransfer;

      let hasAttachments = Boolean(attachments.length);
      let hasLabels = Boolean(labels.length);
      let hasNote = Boolean(note);
      let hasEmail = Boolean(notifyByEmail && email);
      let isPayByInvoice = Boolean(origin === TRANSFER_FLOW_ORIGIN.PAY_BY_INVOICE);
      let account = bankAccount.main ? 'main' : 'other';
      let vatTrue = Boolean(vatRate || vatAmount);
      let hasScheduledDueDate = Boolean(scheduledDate === invoice?.dueDate);
      let scheduled = instant ? 'immediately' : hasScheduledDueDate ? 'due_date' : 'custom';

      this.segment.track('transfer-sepa_summary_confirmed', {
        ...(hasAttachments && { attachments: hasAttachments }),
        ...(hasLabels && { labels: hasLabels }),
        ...(hasNote && { note: hasNote }),
        ...(hasEmail && { email: hasEmail }),
        ...(invoice?.id && { flow: 'byinvoice' }),
        ...(origin && { origin }),
        ...(isPayLater && { financing: isPayLater }),
        ...(invoice && {
          is_einvoice: invoice?.isElectronicInvoice ?? false,
        }),
        ...(variation('feature--boolean-improve-invoice-payment-flows') &&
          (isPayLater || isPayByInvoice) && {
            scheduled,
            account,
            vat_true: vatTrue,
          }),
      });
    }
  }

  get shouldShowInvalidInvoiceDisclaimer() {
    return (
      // @ts-expect-error
      // @ts-expect-error
      this.args.context?.invoice?.isGermanEInvoice &&
      this.args.context?.invoice?.isAttachmentNonFinancial
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::Sepa::New::Summary': typeof FlowsTransfersSepaNewSummaryComponent;
  }
}
