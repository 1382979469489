export default {
  "checks": "hr",
  "content": "hb",
  "form": "hy",
  "title": "hU",
  "description": "hj",
  "wrapper": "hW",
  "aside": "hu",
  "amount-field": "fS"
};
