/* import __COLOCATED_TEMPLATE__ from './tile.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer, SkeletonLoader } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import { CopyToClipboard } from 'qonto/react/shared/components/copy-to-clipboard';

interface BankAccountTileSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class BankAccountTileComponent extends Component<BankAccountTileSignature> {
  CopyToClipboard = CopyToClipboard;
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;
  placeholderLine = SkeletonLoader.Line;

  @service declare router: Services['router'];

  get iban() {
    // @ts-expect-error
    let { shouldDisplayStartVerification, account } = this.args;

    if (shouldDisplayStartVerification) {
      return account.ibanObfuscated;
    }

    return account.iban;
  }

  get lastSyncRelative() {
    // @ts-expect-error
    let { account } = this.args;
    if (!account.lastSyncedAt) return;

    return dayjs(account.lastSyncedAt).fromNow();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BankAccount::Tile': typeof BankAccountTileComponent;
  }
}
