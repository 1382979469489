export default {
  "wrapper": "LG",
  "header": "Lr",
  "status": "Lb",
  "close-button": "Ly",
  "slug": "LU",
  "category": "Lj",
  "category-title": "LW",
  "activity-tag-select": "Lu",
  "footer": "TS"
};
