export default {
  "wrapper": "ad",
  "badge-trial": "aI",
  "header": "at",
  "list": "aM body-2",
  "main": "aP",
  "card": "al",
  "container": "aX",
  "header-button": "aC",
  "error-text": "ak",
  "mt-16": "aJ"
};
