export default {
  "container": "jU",
  "form-core": "jj",
  "label": "jW",
  "request-description": "ju",
  "radio-option": "WS",
  "radio-button": "Wc",
  "inline-inputs": "Wq",
  "placeholder-label": "WZ",
  "placeholder-input": "WR",
  "placeholder-checkbox": "WQ"
};
