export default {
  "details": "Uo",
  "disclaimer": "Un",
  "data": "Ui",
  "confirm": "UA",
  "form": "UY",
  "textarea": "Uh",
  "preview": "Uf",
  "form-title": "UK title1",
  "form-subtitle": "UG title2",
  "divider": "Ur",
  "submit": "Ub",
  "doc": "Uy",
  "amount-input-error": "UU"
};
