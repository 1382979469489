export default {
  "wrapper": "cZr",
  "close-button": "cZb",
  "content-wrapper": "cZy",
  "main": "cZU",
  "main-content": "cZj",
  "disclaimer": "cZW",
  "description": "cZu",
  "mt-26": "cRS",
  "mt-62": "cRc",
  "user-prompt": "cRq",
  "user-prompt-inappropriate-warning": "cRZ",
  "logo-preview": "cRR",
  "static-loader": "cRQ",
  "loading-state-illustration": "cRe",
  "sticky-panel": "cRB"
};
