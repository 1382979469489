export default {
  "row": "Lv",
  "cell": "Lo body-2",
  "empty": "Ln",
  "cell-content": "Li",
  "cell-content--amount": "LA body-1",
  "icon": "LY",
  "description": "Lh",
  "tertiary": "Lf",
  "details": "LK"
};
