export default {
  "header-cell": "xu caption-bold",
  "sortable": "wS",
  "animated": "wc",
  "fadein-item": "wq",
  "animated-cell": "wZ",
  "col1": "wR",
  "header-content": "wQ caption-bold",
  "active": "we"
};
