export default {
  "journey": "je",
  "content": "jB",
  "distance": "jE",
  "google-link-container": "jd",
  "link-icon": "jI",
  "continue-btn": "jt",
  "origin": "jM",
  "input-width": "jP"
};
