export default {
  "two-column-layout": "We",
  "points-list": "WB",
  "point-title": "WE",
  "point-content": "Wd",
  "old-container": "WI",
  "columns": "Wt",
  "left-column": "WM",
  "right-column": "WP",
  "video-wrapper": "Wl",
  "selling-points-wrapper": "WX",
  "actions": "WC"
};
