export default {
  "wrapper": "pY",
  "legend": "ph",
  "grid": "pf",
  "subtitle": "pK body-2",
  "fiscal-code": "pG",
  "personal-data-name": "pr",
  "personal-data-birth": "pb",
  "personal-data": "py",
  "tax-residence": "pU",
  "payer-agent": "pj",
  "errors": "pW",
  "business": "pu",
  "address": "xS"
};
