export default {
  "wrapper": "xg",
  "subtitle": "xD body-2",
  "legend": "xV",
  "table": "xL",
  "footer": "xT",
  "footer-total-c": "xz",
  "footer-total-d": "xa",
  "footer-balance": "xH"
};
