export default {
  "header": "Nm",
  "header-top": "Np",
  "header-inner": "Nx",
  "disclaimer": "Nw mb-16",
  "header-main-wrapper": "NN",
  "header-main": "Nv",
  "logo": "No mr-16",
  "header-right": "Nn",
  "partner-description": "Ni body-2"
};
