import { template as template_9843976446be494085deca81bb9e2516 } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { Badge, Button, ExternalLink } from '@repo/design-system-kit';
import { LOCALES_DEFINITION } from '@repo/shared-config/constants/locales';
import { t } from 'ember-intl';
import ReactBridge from 'qonto/components/react-bridge';
import { Cards } from 'qonto/react/components/account-receivable/empty-state/cards';
import styles from './education.strict-module.css';
export default class EmptyStatesEducation extends Component {
    @service
    intl;
    Badge = Badge;
    Button = Button;
    Cards = Cards;
    externalLink = ExternalLink;
    get hideBadge() {
        return this.args.hideBadge || false;
    }
    get hideDemoCta() {
        return this.args.hideDemoCta || false;
    }
    get navaticTourHref() {
        let userLocale = this.intl.primaryLocale;
        return (this.args.navaticTourUrls[userLocale] ?? this.args.navaticTourUrls[LOCALES_DEFINITION.EN.code]);
    }
    static{
        template_9843976446be494085deca81bb9e2516(`
    <div class={{styles.empty-state-education}} data-test-empty-state-education ...attributes>
      <header class='mb-40 {{styles.header}}'>
        {{#unless this.hideBadge}}
          <ReactBridge
            @reactComponent={{this.Badge}}
            @props={{hash
              type='activate'
              text=(t 'promotion-system.badge.included-in-subscription.label')
              className='mb-24'
            }}
            data-test-empty-state-education-badge
          />
        {{/unless}}

        <h1 class='title-1 mb-24' data-test-empty-state-education-title>
          {{@title}}
        </h1>

        <div class={{styles.actions}}>
          <ReactBridge
            @reactComponent={{this.Button}}
            @props={{hash onPress=@onGetStartedCtaClick variant='primary'}}
            data-test-empty-state-education-get-started-cta
          >
            {{@getStartedCtaText}}
          </ReactBridge>

          {{#unless this.hideDemoCta}}
            <ReactBridge
              @reactComponent={{this.externalLink}}
              @props={{hash
                href=this.navaticTourHref
                display='button'
                variant='secondary'
                target='_blank'
                rel='noopener noreferrer'
                onPress=@onDemoCtaClick
              }}
              data-test-empty-state-education-demo-cta
            >
              {{@demoCtaText}}
            </ReactBridge>
          {{/unless}}
        </div>
      </header>

      <ReactBridge
        @reactComponent={{this.Cards}}
        @props={{hash items=@cardsContent className='mb-40'}}
        data-test-empty-state-education-cards
      />

      {{#if @showFooter}}
        <footer class={{styles.footer}} data-test-empty-state-education-footer>
          <div class='title-4 mb-4' data-test-empty-state-education-footer-title>
            {{@footerTitle}}
          </div>
          <div
            class='body-2 {{if @hasImportedInvoice "mb-12" "mb-8"}} {{styles.footer-subtitle}}'
            data-test-empty-state-education-footer-subtitle
          >
            {{@footerSubtitle}}
          </div>

          {{yield to='footerAction'}}

        </footer>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
