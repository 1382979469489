export default {
  "wrapper": "Wz",
  "wrapper-revamp": "Wa",
  "container": "WH",
  "container-revamp": "WO",
  "box-wrapper": "Wm",
  "warning-label": "Wp",
  "subtitle-container": "Wx",
  "info-container": "Ww",
  "card": "WN",
  "disclaimer": "Wv",
  "list": "Wo",
  "list-item": "Wn body-2",
  "total": "Wi"
};
