export default {
  "wrapper": "xc",
  "subtitle": "xq body-2",
  "legend": "xZ",
  "table": "xR",
  "footer": "xQ",
  "footer-codice-1": "xe",
  "footer-codice-2": "xB",
  "footer-total-a": "xE",
  "footer-total-b": "xd",
  "footer-balance": "xI",
  "errors": "xt"
};
