export default {
  "header": "Vo",
  "header__main": "Vn",
  "note": "Vi",
  "review-status": "VA",
  "reviewer-wrapper": "VY",
  "reviewer": "Vh",
  "decline-note": "Vf",
  "decline-note-title": "VK",
  "status-dot": "VG",
  "approved": "Vr",
  "rejected": "Vb"
};
