export default {
  "cockpit-list": "VC",
  "cockpit-item": "Vk",
  "cockpit-item-tile": "VJ",
  "guarding-title": "Vs",
  "guarding-title-icon": "VF",
  "subtitle-link": "Vg",
  "subtitle-wrapper": "VD",
  "subtitle-tooltip": "VV"
};
