export default {
  "form": "NS",
  "fieldset": "Nc",
  "form-body": "Nq",
  "conditional-select": "NZ",
  "conditional-text": "NR",
  "group": "NQ",
  "add-filter-button": "Ne btn btn--tertiary",
  "footer": "NB",
  "btn-icon": "NE",
  "add-icon": "Nd",
  "cancel-button": "NI btn",
  "form-header": "Nt",
  "align-start": "NM",
  "align-right": "NP",
  "button-wrapper": "Nl",
  "wrapper": "NX",
  "label": "NC body-2",
  "input-wrapper": "Nk",
  "input": "NJ input-field body-2",
  "error": "Ns",
  "error-message": "NF"
};
