export default {
  "invite-review": "bG",
  "invite-review-info": "br",
  "invite-review-info--invitee": "bb",
  "disclaimer-button": "by",
  "info-block": "bU",
  "ev": "bj",
  "tooltip": "bW",
  "description": "bu"
};
