export default {
  "container": "Yx",
  "disabled-option": "Yw",
  "content": "YN",
  "spinner": "Yv",
  "subtitle": "Yo",
  "details-table": "Yn",
  "caption": "Yi",
  "tooltip-icon": "YA",
  "tooltip-icon-right": "YY",
  "card-monthly-cost": "Yh",
  "card-monthly-discounted-cost": "Yf",
  "strikethrough": "YK",
  "purple-text": "YG",
  "budget-label": "Yr",
  "card-cost-label": "Yb",
  "card-billing-account": "Yy"
};
