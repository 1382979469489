export default {
  "step-container": "yF",
  "subtitle": "yg",
  "step-container-content": "yD",
  "continue-button": "yV",
  "period-select": "yL",
  "period-dropdown": "yT",
  "readonly": "yz",
  "period-error": "ya",
  "toggle-options-container": "yH",
  "receipt-code-toggle-input": "yO"
};
