export default {
  "wrapper": "xM",
  "legend": "xP",
  "operation-code": "xl",
  "table": "xX",
  "footer": "xC",
  "footer-detraction": "xk",
  "footer-total-g": "xJ",
  "footer-total-h": "xs",
  "footer-net-g-h": "xF"
};
