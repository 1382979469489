export default {
  "container": "Yc",
  "centered": "Yq",
  "content": "YZ",
  "spinner": "YR",
  "holder-selector-wrapper": "YQ mb-16",
  "holder-selector-error": "Ye",
  "info-text": "YB",
  "error-text": "YE",
  "lottie-illustration": "Yd",
  "info-text-1": "YI",
  "info-text-2": "Yt"
};
