export default {
  "step-container": "Km",
  "content": "Kp",
  "section": "Kx",
  "section-content": "Kw",
  "section-content-text": "KN",
  "section-illustration": "Kv",
  "close-form-cta": "Ko",
  "bank-account-select": "Kn"
};
