export default {
  "wrapper": "WA",
  "confirm": "WY",
  "plan-info": "Wh",
  "plan-info-left": "Wf",
  "info-title": "WK body-1",
  "initial-trial-content": "WG",
  "extra-fees-item": "Wr body-2",
  "extra-fees-container": "Wb",
  "bottom-wrapper": "Wy",
  "total-price-container": "WU",
  "extra-fees-element": "Wj body-1",
  "border": "WW",
  "subtitle": "Wu body-2",
  "group-item": "uS",
  "amount": "uc",
  "subscription-billing-summary": "uq",
  "summary": "uZ",
  "summary-list": "uR",
  "disclaimer": "uQ",
  "disclaimer-revamp": "ue",
  "error": "uB",
  "error-link": "uE",
  "warning-wrapper": "ud",
  "warning-label": "uI body-1"
};
