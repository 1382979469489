export default {
  "row": "zN body-1",
  "active": "zv",
  "cell": "zo body-2",
  "empty": "zn",
  "no-padding": "zi",
  "cell-content": "zA",
  "item-disabled": "zY",
  "item-amount": "zh body-1",
  "centered-cell": "zf",
  "debitor": "zK",
  "item-counterparty-name": "zG",
  "item-counterparty-reference": "zr",
  "item-counterparty-name-status": "zb",
  "item-counterparty-name-text": "zy",
  "amount-on-hold": "zU",
  "icon-on-hold": "zj"
};
