export default {
  "step-container": "Ke",
  "content": "KB",
  "detail": "KE",
  "secondary": "Kd",
  "reference": "KI",
  "fees-label": "Kt",
  "fees-tooltip": "KM",
  "separator": "KP",
  "mt-32": "Kl"
};
