export default {
  "dual-nav": "aU",
  "header": "aj",
  "expanded": "aW",
  "collapsed": "au",
  "responsive": "HS",
  "overlay": "Hc",
  "visible": "Hq",
  "footer": "HZ",
  "scrolled-top": "HR",
  "scrollable-content": "HQ",
  "safari": "He"
};
