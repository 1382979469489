export default {
  "container": "vP",
  "company-details": "vl",
  "sideview": "vX",
  "form": "vC",
  "french-company-details-fieldset": "vk",
  "footer": "vJ",
  "pdf-preview": "vs",
  "de-layout": "vF",
  "de-pdf-preview": "vg",
  "german-preview": "vD"
};
