export default {
  "step-container": "ccv",
  "content": "cco",
  "sticky-panel": "ccn",
  "banner": "cci",
  "banner-content": "ccA",
  "banner-text": "ccY",
  "banner-image": "cch",
  "banner-title": "ccf",
  "banner-description": "ccK body-2",
  "link": "ccG"
};
