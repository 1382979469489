export default {
  "container": "Gm",
  "content": "Gp",
  "buttons": "Gx",
  "genders": "Gw",
  "birth-details": "GN",
  "has-error": "Gv",
  "gender-option": "Go",
  "radio-button": "Gn"
};
