export default {
  "container": "oQ",
  "numbering": "oe",
  "sideview": "oB",
  "form": "oE",
  "numbering-fields": "od",
  "row": "oI",
  "label-tooltip": "ot",
  "tooltip-icon": "oM",
  "legend": "oP",
  "next-invoice-number": "ol",
  "pdf-preview": "oX",
  "de-layout": "oC",
  "de-pdf-preview": "ok"
};
