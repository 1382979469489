export default {
  "content": "cSQ",
  "error-message": "cSe",
  "wrapper": "cSB",
  "banner": "cSE",
  "banner-content": "cSd",
  "banner-text": "cSI",
  "banner-image": "cSt",
  "banner-title": "cSM",
  "banner-description": "cSP body-2",
  "link": "cSl",
  "plans-comparison": "cSX",
  "plan-box": "cSC",
  "arrow": "cSk",
  "arrow-icon": "cSJ",
  "link-downgrade": "cSs",
  "stroke-text": "cSF"
};
