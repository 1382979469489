export default {
  "sidebar": "gi",
  "scroll": "gA",
  "header": "gY",
  "close": "gh",
  "vat": "gf",
  "labels": "gK",
  "actions": "gG",
  "buttons-container": "gr"
};
