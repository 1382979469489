export default {
  "row": "zq body-1",
  "active": "zZ",
  "cell": "zR body-2",
  "empty": "zQ",
  "no-padding": "ze",
  "cell-content": "zB",
  "debitor": "zE",
  "avatar": "zd",
  "debitor-details": "zI",
  "debitor-name": "zt",
  "reference": "zM",
  "amount": "zP",
  "row--sidebar-open": "zl",
  "status": "zX"
};
