export default {
  "row": "NK",
  "active": "NG",
  "cell": "Nr",
  "empty": "Nb",
  "supplier": "Ny",
  "supplier-content": "NU",
  "declined": "Nj",
  "total-repayment": "NW body-1",
  "amount": "Nu",
  "supplier-avatar": "vS mr-16",
  "financing-status": "vc body-2",
  "installments": "vq body-2",
  "overflow-hidden": "vZ",
  "ellipsis": "vR",
  "completed-on": "vQ",
  "financed-amount": "ve body-2"
};
