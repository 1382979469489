export default {
  "header-cell": "zC",
  "empty": "zk",
  "right": "zJ",
  "debitor": "zs",
  "date": "zF",
  "status": "zg",
  "amount": "zD",
  "header-amount": "zV",
  "header-content": "zL caption-bold",
  "active": "zT"
};
