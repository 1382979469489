export default {
  "mandates": "mf",
  "mandates-list": "mK",
  "left-section": "mG",
  "header-search": "mr",
  "header": "mb",
  "header-content": "my",
  "search": "mU",
  "search-bar": "mj",
  "body": "mW",
  "list-title": "mu caption-bold",
  "details": "pS",
  "avatar": "pc mr-16",
  "header-beneficiary": "pq",
  "header-beneficiary-activity": "pZ",
  "header-beneficiary-details": "pR",
  "header-beneficiary-details-recurring": "pQ",
  "infos": "pe",
  "infos-title": "pB",
  "infos-empty": "pE",
  "infos-list": "pd",
  "infos-list-item": "pI",
  "infos-list-item-left": "pt",
  "infos-list-item-right": "pM",
  "infos-list-item-attachment": "pP"
};
