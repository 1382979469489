export default {
  "wrapper": "cqK input-field mb-4",
  "error": "cqG",
  "inactive": "cqr",
  "hidden-value": "cqb",
  "percentage-sign": "cqy",
  "black": "cqU",
  "input-field": "cqj",
  "disabled": "cqW"
};
