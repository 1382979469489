export default {
  "guests-loading": "cRA",
  "header": "cRY",
  "body": "cRh",
  "members": "cRf",
  "title": "cRK caption-bold",
  "placeholder-container": "cRG",
  "icon": "cRr",
  "details": "cRb"
};
