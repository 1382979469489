export default {
  "container": "oT",
  "summary": "oz",
  "sideview": "oa",
  "steps-container": "oH",
  "invoice-upload-container": "oO",
  "pdf-preview": "om",
  "uploader": "op",
  "template-text": "ox",
  "file-dropzone": "ow",
  "overlay": "oN",
  "footer": "ov",
  "upload-prompt": "oo",
  "step-selector": "on",
  "disclaimer": "oi"
};
