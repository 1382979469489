export default {
  "container": "cZm",
  "container--collapsed": "cZp",
  "spin": "cZx",
  "cta-tooltip": "cZw",
  "cta--collapsed": "cZN",
  "cta--expanded": "cZv",
  "collapsed-svg-icon": "cZo",
  "expanded-svg-icon": "cZn",
  "generate-logo-cta": "cZi",
  "badge": "cZA",
  "scaleDown": "cZY",
  "fadeIn": "cZh",
  "textRiseButton": "cZf",
  "cta-description": "cZK",
  "textRiseDescription": "cZG"
};
