export default {
  "header-cell": "Lc",
  "type": "Lq",
  "request-date": "LZ",
  "amount": "LR",
  "quick-actions": "LQ",
  "empty": "Le",
  "header-content": "LB caption-bold",
  "active": "LE"
};
