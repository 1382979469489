export default {
  "header-cell": "Tw",
  "empty": "TN",
  "right": "Tv",
  "debitor": "To",
  "frequency": "Tn",
  "date": "Ti",
  "status": "TA",
  "amount": "TY",
  "header-content": "Th caption-bold",
  "active": "Tf",
  "row--sidebar-open": "TK"
};
