export default {
  "envelope": "VL",
  "email": "VT",
  "logo": "Vz",
  "subject": "Va",
  "message": "VH",
  "payment-details": "VO",
  "contact": "Vm",
  "detail": "Vp",
  "cta": "Vx"
};
