export default {
  "row": "TG",
  "icon": "Tr",
  "cell": "Tb",
  "empty": "Ty",
  "no-padding": "TU",
  "cell-content": "Tj",
  "right": "TW",
  "cube": "Tu",
  "row--sidebar-open": "zS",
  "status": "zc"
};
