export default {
  "row": "Dz",
  "active": "Da",
  "cell": "DH",
  "empty-cell": "DO",
  "counterparty": "Dm",
  "counterparty-info": "Dp",
  "name": "Dx body-1",
  "qonto": "Dw",
  "currency": "DN",
  "account-number": "Dv caption",
  "custom-labels": "Do",
  "label": "Dn"
};
