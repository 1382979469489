export default {
  "full-height": "ccg",
  "centered": "ccD",
  "ocr-animation": "ccV",
  "separator": "ccL",
  "vat-accounting": "ccT",
  "label-title": "ccz",
  "label-select-override": "cca",
  "amount-and-reference-wrapper": "ccH",
  "amount-wrapper": "ccO",
  "payable-amount-tooltip": "ccm",
  "sticky-panel": "ccp",
  "accordion": "ccx",
  "chips-wrapper": "ccw",
  "chip": "ccN"
};
