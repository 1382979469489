export default {
  "container": "Ng",
  "header-wrapper": "ND mb-16",
  "header": "NV",
  "logo": "NL mr-16",
  "title": "NT title-4",
  "description": "Nz body-2",
  "conditions": "Na",
  "label": "NH caption mb-4",
  "tags": "NO"
};
