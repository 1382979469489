export default {
  "color-option": "cZl",
  "color-dot": "cZX",
  "any": "cZC",
  "red": "cZk",
  "orange": "cZJ",
  "yellow": "cZs",
  "green": "cZF",
  "blue": "cZg",
  "purple": "cZD",
  "pink": "cZV",
  "black": "cZL",
  "grey": "cZT",
  "brown": "cZz",
  "color-name": "cZa"
};
