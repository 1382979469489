export default {
  "mandate-new-no-mandate": "rS",
  "left-side": "rc",
  "left-side__wrapper": "rq",
  "left-side__wrapper-title": "rZ",
  "left-side__wrapper-details": "rR",
  "left-side__wrapper-details-item": "rQ",
  "right-side": "re",
  "multi-accounts": "rB",
  "right-side__wrapper": "rE",
  "right-side__wrapper-name": "rd",
  "right-side__wrapper-address": "rI",
  "right-side__wrapper-iban-dashed": "rt",
  "right-side__wrapper-address-label": "rM",
  "right-side__wrapper-iban-label": "rP",
  "right-side__wrapper-bic-label": "rl",
  "right-side__wrapper-address-dashed": "rX",
  "right-side__wrapper-bic-dashed": "rC",
  "dropdown-icon": "rk",
  "dropdown": "rJ",
  "multi-accounts-illustration": "rs"
};
