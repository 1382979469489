export default {
  "container": "Aw",
  "container-metal": "AN",
  "content": "Av",
  "button": "Ao",
  "embossed-selector": "An",
  "carousel-container": "Ai",
  "carousel": "AA",
  "asset": "AY",
  "poster": "Ah",
  "video-embossed": "Af",
  "video": "AK",
  "active": "AG",
  "embossed": "Ar",
  "safari": "Ab",
  "poster-open": "Ay"
};
