export default {
  "container": "no",
  "container-content": "nn",
  "content": "ni",
  "underline": "nA",
  "inline-content": "nY",
  "payment-rate": "nh",
  "payment-subtitle": "nf",
  "grey-text": "nK",
  "earnings-header": "nG",
  "earnings-tooltip": "nr"
};
