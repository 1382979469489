export default {
  "container": "ob",
  "customize-field": "oy",
  "content": "oU",
  "sideview": "oj",
  "form": "oW",
  "footer": "ou",
  "layout": "nS",
  "pdf-preview": "nc",
  "german-preview": "nq"
};
