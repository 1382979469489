export default {
  "connection-sidebar-header": "Fm",
  "title": "Fp",
  "close": "Fx",
  "api-client": "Fw",
  "api-client-avatar": "FN",
  "api-client-name": "Fv",
  "disclaimer": "Fo",
  "expired": "Fn",
  "connect-button": "Fi"
};
