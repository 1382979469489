export default {
  "wrapper": "it",
  "wrapper-new": "iM",
  "icon-list": "iP",
  "list-item": "il",
  "box-wrapper": "iX",
  "panel": "iC",
  "panel-top": "ik",
  "list": "iJ",
  "container": "is",
  "column": "iF",
  "icon": "ig",
  "column-right": "iD"
};
