export default {
  "details": "gb",
  "top-details": "gy",
  "name": "gU",
  "account-infobox": "gj",
  "row": "gW",
  "icon": "gu",
  "account-number": "DS",
  "currency": "Dc",
  "activity-tag": "Dq",
  "activity-icon": "DZ",
  "edit-btn": "DR",
  "action-buttons-container": "DQ"
};
