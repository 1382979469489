export default {
  "voucher-wrapper": "cSp",
  "left-side": "cSx",
  "left-side__wrapper": "cSw",
  "voucher-illustration": "cSN",
  "left-side__wrapper-title": "cSv",
  "voucher-list": "cSo",
  "left-side__wrapper-details": "cSn",
  "right-side": "cSi",
  "multi-accounts": "cSA",
  "right-side__wrapper": "cSY"
};
