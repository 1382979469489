export default {
  "wrapper": "Ot",
  "active": "OM",
  "expanded": "OP",
  "fade-in": "Ol",
  "hidden": "OX",
  "visible": "OC",
  "item-list": "Ok",
  "item-list-slide-down": "OJ",
  "dropdown-trigger": "Os overlay",
  "settings-dropdown-slide-down": "OF",
  "responsive": "Og",
  "is-selected": "OD",
  "is-active": "OV",
  "dropdown": "OL"
};
