export default {
  "row": "Ld body-1",
  "cell": "LI",
  "empty": "Lt",
  "amount": "LM",
  "review": "LP",
  "cell-content": "Ll",
  "icon": "LX",
  "name": "LC",
  "name-text": "Lk",
  "quick-actions": "LJ",
  "account-select": "Ls"
};
