export default {
  "wrapper": "bS",
  "promotion-title": "bc",
  "comparison-table": "bq",
  "table-head": "bZ",
  "title-wrapper": "bR",
  "illustration-wrapper": "bQ",
  "illustration": "be",
  "role-wrapper": "bB",
  "role-name": "bE",
  "role-disabled": "bd",
  "role-access": "bI",
  "disabled-caption": "bt",
  "table-head-sticky": "bM",
  "show-border": "bP",
  "group-row": "bl",
  "table-body": "bX",
  "group": "bC",
  "table-body-row": "bk",
  "feature-description": "bJ body-2",
  "feature": "bs body-2",
  "group-spacer": "bF"
};
