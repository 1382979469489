export default {
  "wrapper": "xo",
  "legend": "xn",
  "input": "xi",
  "table": "xA",
  "footer": "xY",
  "footer-total-m": "xh",
  "footer-total-n": "xf",
  "footer-balance": "xK"
};
