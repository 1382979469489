/* import __COLOCATED_TEMPLATE__ from './addons.hbs'; */
import { action, setProperties } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer, Spinner, ToggleButton } from '@repo/design-system-kit';
import {
  AddOnDetailLoaderWithButton,
  SubscriptionError,
  SubscriptionHeader,
} from '@repo/domain-kit/pricing';
import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { Addons } from 'qonto/constants/addons';
import {
  apiBaseURL,
  apiNamespace,
  billingNamespace,
  billingV5Namespace,
} from 'qonto/constants/hosts';
import { SUBSCRIPTION_RECURRENCES } from 'qonto/constants/subscriptions';
import { codeToName } from 'qonto/models/subscription-product';
import { AddonCards } from 'qonto/react/components/flows/addon-change/addon-cards';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

import type { EstimationError } from './dataContext.type';

// @ts-expect-error
import lottie from '/lotties/error-404.json';

interface FlowsAddonChangeAddonsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsAddonChangeAddonsComponent extends Component<FlowsAddonChangeAddonsSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare networkManager: Services['networkManager'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];
  @service declare router: Services['router'];

  toggleButton = ToggleButton;
  loaderWithButton = AddOnDetailLoaderWithButton;
  disclaimer: typeof Disclaimer.Block = Disclaimer.Block;
  subscriptionHeader = SubscriptionHeader;
  subscriptionError = SubscriptionError;
  addonCards = AddonCards;
  SUBSCRIPTION_RECURRENCES = SUBSCRIPTION_RECURRENCES;
  lottie = lottie;
  spinner = Spinner;

  @tracked addons = [];
  @tracked traits = [];
  @tracked selectedAddonCode = '';
  @tracked shouldSkipAddon = true;

  constructor(owner: unknown, args: FlowsAddonChangeAddonsSignature['Args']) {
    super(owner, args);
    this.initTask.perform().catch(ignoreCancelation);
  }

  get hasFourAddons(): boolean {
    return variation('feature--boolean-cash-flow-first-time-experience');
  }

  get currentSubscriptionRecurrence() {
    return this.subscriptionManager.currentSubscription?.recurrence;
  }

  get shouldShowRecurrenceDisclaimer() {
    return (
      this.currentSubscriptionRecurrence === SUBSCRIPTION_RECURRENCES.MONTHLY &&
      // @ts-expect-error
      this.args.context.recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL
    );
  }

  get showDisclaimerForItalianOrganizations() {
    return (
      variation('feature--boolean-pricing-italian-disclaimers') &&
      this.organizationManager.organization.legalCountry === 'IT'
    );
  }

  get italianDisclaimerText() {
    return this.intl.t('subscription.change.bank-of-italy-disclaimer.body', {
      // @ts-expect-error
      faqUrl: htmlSafe(
        `<a href="https://support-it.qonto.com/hc/it/articles/26999640842513-Restrizioni-in-Italia-e-miglioramento-delle-misure-di-antiriciclaggio" target="_blank" rel="noopener noreferrer"
            data-test-addons-italian-disclaimer-link>${this.intl.t(
              'subscription.change.bank-of-italy-disclaimer.link'
            )}</a>`
      ),
      htmlSafe: true,
    });
  }

  get showDisclaimerForLegacyPlans() {
    return this.subscriptionManager.currentPricePlan?.disabled;
  }

  get showDisclaimer() {
    return (
      this.showDisclaimerForItalianOrganizations ||
      this.showDisclaimerForLegacyPlans ||
      this.shouldShowRecurrenceDisclaimer
    );
  }

  // @ts-expect-error
  confirmationType(addonCode) {
    // @ts-expect-error
    let recurrence = this.args.context.recurrence;
    let isAddedWithAnyRecurrence = Boolean(this.subscriptionManager.getProduct(addonCode));

    let isAddedWithRecurrence = this.subscriptionManager.hasProductAndRecurrence(
      addonCode,
      recurrence
    );
    if (isAddedWithRecurrence) {
      return 'removal';
    }
    if (!isAddedWithAnyRecurrence) {
      return 'add';
    }
    if (recurrence === SUBSCRIPTION_RECURRENCES.MONTHLY) {
      return 'downsize';
    }
    if (recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL) {
      return 'upsize';
    }
    return '';
  }

  @action
  // @ts-expect-error
  selectRecurrence(recurrence) {
    this.segment.track('addon_management_toggle_clicked', {
      recurrence,
    });
    // @ts-expect-error
    setProperties(this.args.context, {
      recurrence,
    });
  }

  @action
  // @ts-expect-error
  onHyperlinkClick(addonCode) {
    this.segment.track('addon_management_annual-hyperlink_clicked', {
      addon_code: addonCode,
    });
    // @ts-expect-error
    setProperties(this.args.context, {
      recurrence: this.SUBSCRIPTION_RECURRENCES.ANNUAL,
    });
  }

  @action
  // @ts-expect-error
  onConfirm(addon) {
    let isRemoval = Boolean(
      this.subscriptionManager.hasProductAndRecurrence(
        addon.code,
        // @ts-expect-error
        this.args.context.recurrence
      )
    );
    this.trackCtaClick({ isRemoval, addon });

    // @ts-expect-error
    setProperties(this.args.context, {
      isRemoval,
    });

    // Remove the addon
    if (isRemoval) {
      // @ts-expect-error
      setProperties(this.args.context, {
        targetAddon: addon,
        // @ts-expect-error
        tracking: this.getTrackingInfo(addon.code),
      });
      // @ts-expect-error
      this.args.transitionToNext();
    }
    // Add the addon
    else {
      // @ts-expect-error
      this.confirmTask.perform(addon).catch(ignoreCancelation);
    }
  }

  @action
  reloadAddons() {
    this.initTask.perform().catch(ignoreCancelation);
  }

  // @ts-expect-error
  trackCtaClick({ isRemoval, addon }) {
    let isAdded = this.subscriptionManager.getProduct(addon.code);
    // @ts-expect-error
    let recurrence = this.args.context.recurrence;
    let hasAvailableTrial = this.subscriptionManager.hasAvailableTrialProduct(
      addon.code,
      recurrence
    );

    let ctaType = '';
    if (isRemoval) {
      ctaType = 'remove';
    } else if (!isAdded) {
      if (this.subscriptionManager.currentSubscription?.hasInitialTrial) {
        ctaType = 'try_for_free';
      } else if (hasAvailableTrial) {
        ctaType = 'start_free_trial';
      } else {
        ctaType = 'add_to_plan';
      }
    } else if (recurrence === SUBSCRIPTION_RECURRENCES.MONTHLY) {
      ctaType = 'switch_monthly';
    } else if (recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL) {
      ctaType = 'switch_annually';
    }

    this.segment.track('addon_management_cta_clicked', {
      cta_type: ctaType,
      addon_code: addon.code,
      addon_current_toggle_recurrence: recurrence,
    });
  }

  initTask = dropTask(async () => {
    // @ts-expect-error
    if (!this.args.context.addonCode) {
      this.shouldSkipAddon = false;
      this.segment.track('addon_management_displayed', {
        origin: this.router.currentRoute?.queryParams?.origin || 'none',
      });
    }

    try {
      let response = await this.fetchAddonsTask.perform();
      if (!this.hasFourAddons) {
        // Hide CFM add-on for non-CFM users
        this.addons = response.products.filter(
          ({ code }: { code: string }) => code !== Addons.CashFlowManagement
        );
      } else {
        this.addons = response.products;
      }

      // If we pass an addon code in the query params, we directly go to the checkout page
      // This is used for upsell flows
      // @ts-expect-error
      if (this.args.context.addonCode) {
        // @ts-expect-error
        let addon = response.products.find(({ code }) => code === this.args.context.addonCode);
        // @ts-expect-error
        await this.confirmTask.perform(addon, this.args.context.recurrence);
        this.shouldSkipAddon = false;
      }

      this.traits = response.traits;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  fetchAddonsTask = dropTask(async () => {
    return await this.networkManager.request(
      `${apiBaseURL}/${apiNamespace}/pricing-catalog/products?type=addon&legal_country=${this.organizationManager.organization.legalCountry}`
    );
  });

  confirmTask = dropTask(async (addon, rec) => {
    try {
      // @ts-expect-error
      let recurrence = rec ?? this.args.context.recurrence;
      let namespace = this.organizationManager.organization.hasAddonUpsellCheckout
        ? billingV5Namespace
        : billingNamespace;
      this.selectedAddonCode = addon.code;
      let payload = await this.networkManager.request(
        `${apiBaseURL}/${namespace}/subscriptions/estimate`,
        {
          method: 'POST',
          data: this.organizationManager.organization.hasAddonUpsellCheckout
            ? {
                organization_id: this.organizationManager.organization.id,
                selected_products: [
                  {
                    product_id: addon.id,
                    recurrence,
                    main_selection: true,
                  },
                ],
              }
            : {
                subscription: {
                  organization_id: this.organizationManager.organization.id,
                  product_id: addon.id,
                  recurrence,
                },
              },
        }
      );
      this._handleNextStepWithData(payload, addon);
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.httpStatus === 422) {
        // @ts-expect-error
        this._handleNextStepWithData(error.responseJSON, addon);
        return;
      }
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      let errorMessage = this.intl.t('toasts.errors.generic');
      this.toastFlashMessages.toastError(errorMessage);
    }
  });

  // @ts-expect-error
  getTrackingInfo(addonCode, pricePlanCode) {
    // @ts-expect-error
    let recurrence = this.args.context.recurrence;
    return {
      origin: this.router.currentRoute?.queryParams?.origin || 'other',
      confirmation_type: this.confirmationType(addonCode),
      addon_code: addonCode,
      addon_recurrence: recurrence,
      current_plan_recurrence: this.currentSubscriptionRecurrence,
      current_plan: this.subscriptionManager.currentPricePlan.code,
      target_plan: pricePlanCode || this.subscriptionManager.currentPricePlan.code,
    };
  }

  // @ts-expect-error
  _handleNextStepWithData(payload, addon) {
    let { warnings, extra, target_subscriptions, total_estimate, errors, suggested_products } =
      payload;
    // @ts-expect-error
    let hasInsufficientFunds = errors?.some(it => it.code === 'balance_insufficient_funds');
    let isAddonBlocked = // TODO: remove it when we have the correct type on payload
      errors?.some((it: EstimationError) => it.code === 'boi_change_disallowed');
    // @ts-expect-error
    let addonInfo = target_subscriptions.find(ep => ep.product_type === 'addon');
    addonInfo.benefits = addon.benefit_groups[0].benefits;
    // @ts-expect-error
    addonInfo.productName = this.addons.find(a => a.code === addon.code).brand_name;

    // @ts-expect-error
    let pricePlan = target_subscriptions.find(ep => ep.product_type === 'core');
    if (pricePlan) {
      pricePlan.productName = codeToName(this.intl, pricePlan.product_group_code);
    }

    this._handleNextStep({
      warnings,
      extra,
      total_estimate,
      hasInsufficientFunds,
      isAddonBlocked,
      errors,
      suggested_products,
      target_subscriptions,
      targetAddon: addonInfo,
      targetPricePlan: pricePlan,
      tracking: this.getTrackingInfo(addonInfo.product_code, pricePlan?.product_code),
    });
  }

  _handleNextStep({
    // @ts-expect-error
    warnings,
    // @ts-expect-error
    extra,
    // @ts-expect-error
    total_estimate,
    // @ts-expect-error
    targetAddon,
    // @ts-expect-error
    targetPricePlan,
    // @ts-expect-error
    hasInsufficientFunds,
    // @ts-expect-error
    isAddonBlocked,
    // @ts-expect-error
    tracking,
    // @ts-expect-error
    errors,
    // @ts-expect-error
    suggested_products,
    // @ts-expect-error
    target_subscriptions,
  }) {
    // @ts-expect-error
    setProperties(this.args.context, {
      warnings,
      extra,
      total_estimate,
      targetAddon,
      targetPricePlan,
      hasInsufficientFunds,
      isAddonBlocked,
      tracking,
      errors,
      suggestedProducts: suggested_products,
      target_subscriptions,
      // Reset it to make sure the user can hit back
      addonCode: undefined,
      isItalyError: false,
    });
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AddonChange::Addons': typeof FlowsAddonChangeAddonsComponent;
  }
}
