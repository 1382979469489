export default {
  "sub-menu": "Ow",
  "collapsed": "ON",
  "collapse-left": "Ov",
  "responsive": "Oo",
  "expanded": "On",
  "no-slide": "Oi",
  "visible": "OA",
  "hidden": "OY",
  "group": "Oh",
  "promo-box": "Of",
  "title": "OK",
  "quick-access-padding": "OG",
  "scrolled-top": "Or",
  "scrollable-content": "Ob"
};
