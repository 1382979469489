export default {
  "animated": "AP",
  "container": "Al",
  "header": "AX",
  "footer": "AC",
  "skip-cta": "Ak",
  "pricing-caption": "AJ",
  "asset-x": "As",
  "asset-plus": "AF",
  "asset-one": "Ag",
  "show": "AD",
  "selector": "AV",
  "selectorFadeIn": "AL",
  "fadeIn": "AT",
  "onePlusAssetFadeIn": "Az",
  "assetMoveUp": "Aa",
  "xAssetFadein": "AH",
  "feature": "AO",
  "highlighted": "Am"
};
