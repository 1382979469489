export default {
  "dates-filter": "wn",
  "dates-wrapper": "wi",
  "date-interval": "wA",
  "date-field-wrapper": "wY",
  "dates-conditional": "wh",
  "placeholder": "wf",
  "error": "wK",
  "error-message": "wG body-2"
};
