export default {
  "wrapper": "Dr",
  "label": "Db",
  "input": "Dy",
  "icon-trash": "DU",
  "hidden": "Dj",
  "icon-color": "DW",
  "icon-edit": "Du",
  "icon-edit-save": "VS",
  "button-container": "Vc"
};
