export default {
  "step-container": "yh",
  "flex-table": "yf",
  "flex-row": "yK",
  "flex-cell": "yG",
  "with-border": "yr",
  "with-border-top": "yb",
  "secondary": "yy",
  "flex-info": "yU",
  "amount": "yj",
  "legal-name": "yW"
};
