export default {
  "modal": "gk",
  "features": "gJ",
  "close-button": "gs",
  "header": "gF title-2",
  "header-text": "gg body-2",
  "feature": "gD",
  "subheader": "gV title-4",
  "text": "gL body-2",
  "cta": "gT",
  "container-img": "gz",
  "img": "ga",
  "link": "gH"
};
