/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
import { action, setProperties } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { apiBaseURL, billingV5Namespace } from 'qonto/constants/hosts';
import { SUBSCRIPTION_RECURRENCES } from 'qonto/constants/subscriptions';
import { ConfirmDisclaimers } from 'qonto/react/components/flows/subscription-change/confirm/confirm-disclaimers';
import { ConfirmUpgrade } from 'qonto/react/components/flows/subscription-change/confirm/confirm-upgrade';
import type {
  EstimationError,
  EstimationWarning,
  TargetSubscription,
  TotalEstimate,
} from 'qonto/react/components/flows/subscription-change/data-context.type';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface EstimatePayload {
  warnings: EstimationWarning[];
  extra: {};
  total_estimate: TotalEstimate;
  errors: EstimationError[];
  suggested_products: TargetSubscription[];
  target_subscriptions: TargetSubscription[];
}
interface FlowsSubscriptionChangeConfirmSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsSubscriptionChangeConfirmComponent extends Component<FlowsSubscriptionChangeConfirmSignature> {
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;
  confirmDisclaimers = ConfirmDisclaimers;
  confirmUpgrade = ConfirmUpgrade;
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare networkManager: Services['networkManager'];
  @tracked hasProductChangeNotAvailableError = false;

  constructor(owner: unknown, args: FlowsSubscriptionChangeConfirmSignature['Args']) {
    super(owner, args);
    this.sendTrackingEventOnLoad();
  }

  sendTrackingEventOnLoad() {
    // @ts-expect-error
    let { context } = this.args;
    this.segment.track('plans_confirmation_displayed', {
      insufficient_funds: context.hasInsufficientFunds,
      target_plan: context.subscription.product.get('code'),
      recurrence: context.recurrence,
    });
  }

  SUBSCRIPTION_RECURRENCES = SUBSCRIPTION_RECURRENCES;

  get targetSubscription() {
    // @ts-expect-error
    return this.args.context.targetSubscriptions[0];
  }

  get extraPrice() {
    // @ts-expect-error
    return this.args.context.extraPrice;
  }

  get currency() {
    // @ts-expect-error
    return this.args.context.estimatedPrice.vat_included_amount.currency;
  }

  get selectedPricePlan() {
    // @ts-expect-error
    return this.args.context.subscription.product;
  }

  get currentPricePlan() {
    return this.subscriptionManager.currentPricePlan;
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get activeTrialMonthDuration() {
    return this.subscriptionManager.currentSubscription?.activeTrial.month_duration;
  }

  get isUpsizeOrUpgrade() {
    // TODO: remove this once the BE is ready
    return true;
  }

  get intialTrialCallouts() {
    return [
      this.intl.t('subscription.change.confirmation.trial.callout.bullet-1', {
        newPlanName: this.selectedPricePlan.get('localName'),
        monthDuration: this.activeTrialMonthDuration,
      }),
      this.intl.t('subscription.change.confirmation.trial.callout.bullet-2', {
        previousPlanName: this.currentPricePlan.localName,
      }),
      this.intl.t('subscription.change.confirmation.trial.callout.bullet-3'),
    ];
  }

  get showNoRefundDisclaimer() {
    // @ts-expect-error
    return this.args.context.warnings.some(warning => warning.code === 'no_refund');
  }

  get isUpsize() {
    return (
      // @ts-expect-error
      this.args.context.subscription.recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL &&
      this.subscriptionManager.currentSubscription?.recurrence === SUBSCRIPTION_RECURRENCES.MONTHLY
    );
  }

  get showDisclaimerLegacyPricePlan() {
    return this.currentPricePlan.disabled;
  }

  get nextBillingDateFormatted() {
    return dateToken({
      date: this.estimatedPrice?.next_billing_date,
      // @ts-expect-error
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
    });
  }

  get hasAddonUpsellCheckout() {
    return this.organizationManager.organization?.hasAddonUpsellCheckout;
  }

  get showDisclaimerForItalianOrganizations() {
    return (
      variation('feature--boolean-pricing-italian-disclaimers') &&
      this.organizationManager.organization.legalCountry === 'IT'
    );
  }

  get italianDisclaimerText() {
    return this.intl.t('subscription.change.bank-of-italy-disclaimer.body', {
      // @ts-expect-error
      faqUrl: htmlSafe(
        `<a href="https://support-it.qonto.com/hc/it/articles/26999640842513-Restrizioni-in-Italia-e-miglioramento-delle-misure-di-antiriciclaggio" target="_blank" rel="noopener noreferrer"
            data-test-price-plans-confirm-italian-disclaimer-link>${this.intl.t(
              'subscription.change.bank-of-italy-disclaimer.link'
            )}</a>`
      ),
      htmlSafe: true,
    });
  }

  get estimatedPrice() {
    // @ts-expect-error
    return this.args.context.estimatedPrice;
  }

  @action
  onKeepCurrentPlan() {
    this.segment.track('checkout_rejected', {
      current_plan: this.currentPricePlan.get('code'),
      target_plan: this.selectedPricePlan.get('code'),
      // @ts-expect-error
      recurrence: this.args.context.recurrence,
    });

    // @ts-expect-error
    this.args.transitionToPrevious();
  }

  // @ts-expect-error
  confirmationType(productCode) {
    // @ts-expect-error
    let recurrence = this.args.context.recurrence;
    let isSamePlan = Boolean(this.subscriptionManager.getProduct(productCode));

    if (!isSamePlan) {
      return 'add';
    }
    if (recurrence === SUBSCRIPTION_RECURRENCES.MONTHLY) {
      return 'downsize';
    }
    if (recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL) {
      return 'upsize';
    }
  }

  // @ts-expect-error
  getTrackingInfo(targetPlanCode, currentPlanCode) {
    return {
      confirmation_type: this.confirmationType(targetPlanCode),
      current_plan_recurrence: this.subscriptionManager.currentSubscription?.recurrence,
      current_plan: currentPlanCode,
      target_plan: targetPlanCode,
    };
  }

  @action
  onSuggestedProductToggle(isSelected: boolean, product: TargetSubscription) {
    this.confirmTask.perform(isSelected, product).catch(ignoreCancelation);
  }

  get targetSubscriptions(): TargetSubscription[] {
    // @ts-expect-error
    return this.args.context.targetSubscriptions || [];
  }

  confirmTask = dropTask(async (isSelected: boolean, product: TargetSubscription) => {
    try {
      const selectedProducts = isSelected
        ? [
            ...this.targetSubscriptions,
            {
              product_id: product.product_id,
              recurrence: SUBSCRIPTION_RECURRENCES.MONTHLY,
            },
          ]
        : this.targetSubscriptions.filter(({ product_id }) => product_id !== product.product_id);

      let payload = await this.networkManager.request(
        `${apiBaseURL}/${billingV5Namespace}/subscriptions/estimate`,
        {
          method: 'POST',
          data: {
            organization_id: this.organizationManager.organization.id,
            selected_products: selectedProducts.map(updatedProduct => ({
              product_id: updatedProduct.product_id,
              recurrence: updatedProduct.recurrence,
              main_selection: updatedProduct.product_id === this.targetSubscription?.product_id,
            })),
          },
        }
      );
      this._handleData(payload);
    } catch (error) {
      // eslint-disable-next-line no-console -- TODO handle error
      console.log('error', error);
    }
  });
  _handleData(payload: EstimatePayload) {
    let { warnings, extra, total_estimate, errors, suggested_products, target_subscriptions } =
      payload;
    // @ts-expect-error
    setProperties(this.args.context, {
      warnings,
      extraPrice: extra,
      estimatedPrice: total_estimate,
      hasInsufficientFunds: errors?.some(it => it.code === 'balance_insufficient_funds'),
      errors,
      suggestedProducts: suggested_products,
      targetSubscriptions: target_subscriptions,
    });
  }
  savePlanTask = dropTask(async () => {
    this.segment.track(
      'checkout_confirmation_cta_clicked',
      this.getTrackingInfo(this.selectedPricePlan.get('code'), this.currentPricePlan.get('code'))
    );

    let currentPlan = this.currentPricePlan.get('code');
    let targetPlan = this.selectedPricePlan.get('code');

    try {
      // @ts-expect-error
      await this.args.context.subscription.save();
      await this.subscriptionManager.refresh();
      // @ts-expect-error
      this.args.context.hasUpgradedPlan = true;
      // @ts-expect-error
      this.args.context.previousPlan = currentPlan;
      // @ts-expect-error
      this.args.context.newPlan = targetPlan;
      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      // @ts-expect-error
      if (error.status === 422) {
        // @ts-expect-error
        this.hasProductChangeNotAvailableError = error.errors.some(
          // @ts-expect-error
          it => it.detail.code === 'product_change_not_available'
        );

        if (variation('feature--boolean-mp-italy-checkout')) {
          if (this.hasProductChangeNotAvailableError) {
            // @ts-expect-error
            this.args.context.isItalyError = true;
            // @ts-expect-error
            this.args.transitionToNext();
            return;
          }
        }
      }

      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      this.segment.track('checkout_confirmation_error');

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      let errorMessage = this.intl.t('toasts.errors.generic');
      this.toastFlashMessages.toastError(errorMessage);
      return error;
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::SubscriptionChange::Confirm': typeof FlowsSubscriptionChangeConfirmComponent;
  }
}
