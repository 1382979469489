export default {
  "row": "FK body-1",
  "active": "FG",
  "cell": "Fr",
  "empty": "Fb",
  "connection": "Fy",
  "connection-avatar": "FU",
  "connection-name": "Fj",
  "member-name": "FW body-2",
  "member-team": "Fu caption",
  "status": "gS",
  "failure": "gc",
  "error": "gq",
  "hidden": "gZ"
};
