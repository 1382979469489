export default {
  "row": "zz",
  "icon": "za",
  "cell": "zH",
  "empty": "zO",
  "no-padding": "zm",
  "cell-content": "zp",
  "right": "zx",
  "cube": "zw"
};
