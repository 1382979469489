export default {
  "wrapper": "Ht",
  "header": "HM",
  "no-separator": "HP",
  "tabs": "Hl",
  "tab": "HX body-1 overlay",
  "selected": "HC",
  "organization-list": "Hk",
  "content": "HJ",
  "empty-state": "Hs",
  "illustration": "HF",
  "body": "Hg body-1",
  "cta": "HD btn btn--tertiary"
};
