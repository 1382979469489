export default {
  "label-wrapper": "hH body-1",
  "container": "hO",
  "transaction-info": "hm",
  "secondary": "hp",
  "amount-container": "hx",
  "amount": "hw",
  "disabled": "hN",
  "checkbox": "hv",
  "disclaimer": "ho"
};
