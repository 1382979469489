export default {
  "container": "ut",
  "has-background": "uM",
  "wrapper": "uP",
  "error-wrapper": "ul",
  "bottom-btns": "uX",
  "bottom-wrapper": "uC",
  "tab-nav": "uk",
  "tab-nav-small": "uJ",
  "btn-clear": "us",
  "error-illustration": "uF",
  "error-title": "ug title-1",
  "disclaimer-wrapper": "uD",
  "disclaimer": "uV",
  "error-description": "uL body-2",
  "table-wrapper": "uT",
  "title": "uz title-1",
  "lineup-toggle": "ua",
  "description": "uH body-2",
  "loading-wrapper": "uO",
  "card": "um",
  "plan": "up",
  "placeholder-header": "ux",
  "disclaimer-info": "uw",
  "spinner": "uN"
};
