export default {
  "item": "Oc",
  "qonto-pilot-nav-item": "Oq",
  "wrapper": "OZ",
  "collapsed": "OR",
  "label": "OQ body-1",
  "icon-wrapper": "Oe",
  "icon": "OB",
  "key": "OE",
  "keys": "Od"
};
