/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Success } from '@repo/domain-kit/financing';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';
import type { DataContext } from 'qonto/routes/flows/setup/financing/pay-later/topup-data-context';

interface FlowsFinancingPayLaterTopupSuccess {
  Args: FlowStepArgs<DataContext>;
}

export default class FlowsFinancingPayLaterTopupSuccessComponent extends Component<FlowsFinancingPayLaterTopupSuccess> {
  @service declare segment: Services['segment'];

  Success = Success;

  constructor() {
    // @ts-expect-error
    super(...arguments);
    this.segment.track('pay-later-topupflow-success', {
      origin: this.args.context.origin,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Financing::PayLater::Topup::Success': typeof FlowsFinancingPayLaterTopupSuccessComponent;
  }
}
