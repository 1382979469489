export default {
  "sidebar-header": "Tl",
  "sidebar-header__top": "TX",
  "sidebar-header__close-button": "TC",
  "close-button": "Tk",
  "sidebar-header__body": "TJ",
  "sidebar-header__content": "Ts",
  "sidebar-header__date": "TF",
  "infobox": "Tg",
  "infobox--link": "TD",
  "ghost": "TV",
  "fail": "TL",
  "success": "TT"
};
