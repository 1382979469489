/* import __COLOCATED_TEMPLATE__ from './contact.hbs'; */
import { ForbiddenError, InvalidError } from '@ember-data/adapter/error';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { task } from 'ember-concurrency';
import { alias } from 'macro-decorators';

// @ts-expect-error
import { CONCIERGE_CHANNELS } from 'qonto/constants/concierge';

interface FlowsConciergeContactSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsConciergeContact extends Component<FlowsConciergeContactSignature> {
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;

  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare userManager: Services['userManager'];
  @service declare sentry: Services['sentry'];

  CONCIERGE_CHANNELS = CONCIERGE_CHANNELS;

  // @ts-expect-error
  @alias('userManager.currentUser.email') contactEmail;
  // @ts-expect-error
  @alias('userManager.currentUser.phoneNumber') contactPhoneNumber;

  get hasTimeDisclaimer() {
    let hourInParis = dayjs().tz('Europe/Paris').hour();
    return hourInParis < 9 || hourInParis >= 21;
  }

  submitTask = task(async () => {
    try {
      // @ts-expect-error
      let { conciergeRequest } = this.args.context;
      await conciergeRequest.send();
      this.segment.track('concierge_request_sent');
      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      if (error instanceof ForbiddenError || error instanceof InvalidError) {
        this.sentry.captureException(error);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Concierge::Contact': typeof FlowsConciergeContact;
  }
}
