export default {
  "filter": "wD",
  "expression": "wV",
  "conditional-wrapper": "wL mr-8",
  "dropdown-property-select": "wT",
  "is-advanced-filters-mode": "wz",
  "dropdown-operator-select": "wa",
  "conditional-text": "wH",
  "property-select": "wO",
  "operator-select": "wm",
  "error-message": "wp",
  "values-field": "wx",
  "input-placeholder": "ww",
  "filter-actions": "wN",
  "label-color": "wv",
  "button-container": "wo"
};
