export default {
  "wrapper": "mV",
  "content": "mL",
  "compact": "mT",
  "full-width": "mz",
  "left": "ma",
  "right-image": "mH",
  "right-lottie": "mO",
  "badge": "mm",
  "badge-icon": "mp",
  "animation": "mx",
  "hidden": "mw"
};
