export default {
  "tooltip": "HN",
  "wrapper": "Hv",
  "collapsed": "Ho",
  "label": "Hn body-1",
  "selected": "Hi",
  "icon-wrapper": "HA",
  "icon": "HY",
  "locked-icon": "Hh",
  "notification-count": "Hf",
  "fade-in": "HK",
  "responsive": "HG"
};
